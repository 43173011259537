import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["aria"]
  static classes = ["open"]

  toggleMenu() {
    if (this.element.classList.contains(this.openClass)) {
      this.element.classList.remove(this.openClass)
      this.ariaTargets.forEach(target => target.ariaExpanded = false)
    }
    else {
      this.element.classList.add(this.openClass)
      this.ariaTargets.forEach(target => target.ariaExpanded = true)
    }
  }

  toggleItem(event) {
    const parent = event.currentTarget.parentElement;

    if (parent.classList.contains(this.openClass)) {
      parent.classList.remove(this.openClass)
    }
    else {
      parent.classList.add(this.openClass)
    }
  }
}
