import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    srcs: Object
  }

  connect() {
    document.addEventListener("currency-change", event => {
      const currencyName = event.detail.name.toLowerCase()

      if (this.srcsValue[currencyName] === undefined) {
        console.error(`currency ${currencyName} not found in srcsValue`)
        return
      }

      if (this.element.src === this.srcsValue[currencyName]) {
        console.info("frame already loaded with this currency", event.detail)
        return
      }
      else {
        this.element.src = this.srcsValue[currencyName]
        console.info("reload frame with new currency in url ", event.detail)
      }
    })
  }
}
