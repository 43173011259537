import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["default", "selected", "itemList", "arrow"]
  static classes = ["hidden", "selected", "active", "arrowDown", "arrowUp"]

  connect() {
    if (this.hasDefaultTarget) {
      this.setSelected(this.defaultTarget)
    }
  }

  setArrow() {
    if (!this.hasArrowTarget) return
    
    if (this.itemListTarget.classList.contains(this.hiddenClass)) {
      this.arrowTarget.classList.replace(this.arrowUpClass, this.arrowDownClass)
    }
    else {
      this.arrowTarget.classList.replace(this.arrowDownClass, this.arrowUpClass)
    }
  }

  setSelected(element) {
    // hide the selected from the list of options
    Array.from(this.itemListTarget.children).forEach(item => { item.classList.remove(this.hiddenClass) })
    element.classList.add(this.hiddenClass)

    let replaceWith = [element.innerHTML]
    if (this.hasArrowTarget) replaceWith.push(this.arrowTarget)

    this.selectedTarget.replaceChildren(...replaceWith)
  }

  toggle(event) {
    this.itemListTarget.classList.toggle(this.hiddenClass)
    this.selectedTarget.classList.toggle(this.activeClass)
    this.setArrow()
  }

  outsideClose(event) {
    if(!this.element.contains(event.target)) {
      this.itemListTarget.classList.add(this.hiddenClass)
      this.selectedTarget.classList.remove(this.activeClass)
      this.setArrow()
    }
  }

  select(event) {
    this.setSelected(event.currentTarget)
  }
}
