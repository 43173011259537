import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr"

require("flatpickr/dist/flatpickr.css")

export default class extends Controller {
  connect() {
    flatpickr(this.element, {
      dateFormat: "F j, Y",
      enable: [
      {
        from: new Date().fp_incr(1),
        to: new Date().fp_incr(730)
      }
      ],
    })
  }
}
