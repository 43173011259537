import { Controller } from "@hotwired/stimulus"
import { createChart, CrosshairMode } from 'lightweight-charts';
import dayjs from 'dayjs';

let throttle = require("lodash.throttle");

export default class extends Controller {
  static values = {
    priceEndpoint: String,
    currency: { type: String, default: 'ada' },
    currencySymbol: { type: String, default: '₳' },
    timeFrame: { type: String, default: '24h' },
  }

  static classes = ['priceUp', 'priceDown']

  static targets = [
    'currentPrice',
    'priceChange',
    'priceChangeDuration',
  ]

  initialize() {
    this.resizeChart = throttle(this.resizeChart, 100)
  }

  async setCurrency(currency) {
    if (this.currencyValue != currency.name.toLowerCase()) {
      this.currencyValue = currency.name.toLowerCase()
      this.currencySymbolValue = currency.symbol
      this.useExistingPriceData = true

      this.renderChart()
    }
  }

  async changeTimeFrame(event) {
    this.timeFrameValue = event.currentTarget.getAttribute("time-frame")
    this.useExistingPriceData = false

    this.renderChart()
  }

  async chartTargetConnected(element) {
    this.chartContainer = element;

    this.initChart();
    this.renderChart();

    document.addEventListener("currency-change", event => { this.setCurrency(event.detail) })

    window.setInterval(() => {
      this.renderChart()
    }, 5000);
  }

  async initChart() {
    let chartHeight = parseFloat(window.getComputedStyle(this.chartContainer).height);
    let chartWidth = parseFloat(window.getComputedStyle(this.chartContainer).width);

    this.chart = createChart(this.chartContainer, {
      width: chartWidth,
      height: chartHeight,
      layout: {
        backgroundColor: '#ffffff',
        textColor: 'rgba(33, 56, 77, 1)',
      },
      grid: {
        vertLines: {
          color: 'rgba(197, 203, 206, 0.0)',
        },
        horzLines: {
          color: 'rgba(197, 203, 206, 0.0)',
        },
      },
      rightPriceScale: {
        borderVisible: false,
      },
      leftPriceScale: {
        borderVisible: false,
      },
      priceScale: {
        autoScale: false, // Disable auto-scaling
      },
      timeScale: {
        borderVisible: false,
        timeVisible: true,
      },
      crosshair: {
        mode: CrosshairMode.Normal,
      },
      handleScroll: false, // Disable scroll events
      handleScale: false, // Disable zoom events
    });

    this.areaSeries = this.chart.addAreaSeries({
      topColor: '#dfd4ff',
      bottomColor: '#dfd4ff0f',
      lineColor: '#4D3C80',
      lineWidth: 1,
      priceFormat: {
        type: 'price',
        precision: 5,
        minMove: 0.00001
      },
      lastValueVisible: true,
      priceLineVisible: true,
    });

    this.chart.priceScale('right').applyOptions({
      scaleMargins: {
        top: 0.35,
        bottom: 0.2,
      },
    });
  }

  resizeChart() {
    this.chartContainer.removeChild(this.chartContainer.firstChild);
    this.useExistingPriceData = true;
    this.initChart();
    this.renderChart();
    this.useExistingPriceData = false;
  }

  async renderChart() {
    if(this.useExistingPriceData != true) {
      this.priceData = await this.getPriceData();
    }

    this.updateCurrentPrice()
    this.updatePriceChange()

    const priceData = this.formatPriceData(this.priceData[this.timeFrameValue+'_'+this.currencyValue])
    
    this.areaSeries.setData(priceData);
    this.chart.timeScale().fitContent();
  }

  updateCurrentPrice() {
    this.currentPrice = this.priceData['current_price_' + this.currencyValue];
    this.currentPriceTarget.innerText = this.currentPrice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 12 });
    this.currentPriceTarget.classList.remove("ada", "usd");
    this.currentPriceTarget.classList.add(this.currencyValue);
  }

  updatePriceChange() {
    this.priceChangePercentage = this.priceData[this.timeFrameValue + '_price_change_' +this.currencyValue];
    this.priceChangeTarget.lastElementChild.innerText = Math.abs(this.priceChangePercentage / 100).toLocaleString('en-US', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 });
    this.priceChangeDurationTarget.innerText = this.timeFrameValue;
    
    if(this.priceChangePercentage < 0) {
      this.priceChangeTarget.classList.remove(this.priceUpClass);
      this.priceChangeTarget.classList.add(this.priceDownClass);
    } else {
      this.priceChangeTarget.classList.remove(this.priceDownClass);
      this.priceChangeTarget.classList.add(this.priceUpClass);
    }
  }

  async getPriceData() {
    return fetch(this.priceEndpointValue, {
      method: 'GET',
      headers: { 'Accept': 'application/json' }
    }).then (response => response.json());
  }

  formatPriceData(dataObject) {
    const formattedData = [];

    for (const timestamp in dataObject) {
      if (dataObject.hasOwnProperty(timestamp)) {
        const value = dataObject[timestamp];
        formattedData.push({ time: dayjs(timestamp).unix(), value: value });
      }
    }

    // Sort the formatted data by time in ascending order
    formattedData.sort((a, b) => a.time - b.time);
    return formattedData;
  }
}
