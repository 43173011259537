import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    interval: { type: Number, default: 60000 },
    morph: { type: Boolean, default: true },
  }

  connect() {
    this.refreshInterval = setInterval(() => {
      this.element.reload();
    }, this.intervalValue);
  }
}
