import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "pane", "currentTab"]
  static classes = ["active"]
  static values = {
    preventDefault: { type: Boolean, default: false }
  }

  connect() {
    const tabAction = this.preventDefaultValue ? "click->tabs#showTab:prevent" : "click->tabs#showTab"

    this.tabTargets.forEach(tab => tab.dataset.action = tabAction)
    if (this.hasCurrentTabTarget) {
      this.showTab({ currentTarget: this.currentTabTarget })
    } else {
      this.showTab({ currentTarget: this.tabTargets[0] })
    }
  }

  showTab(event) {
    this.currentTab = event.currentTarget
    this.currentPane = this.paneTargets.find(pane => pane.dataset.id == this.currentTab.dataset.id)

    this.tabTargets.forEach(tab => tab.classList.remove(this.activeClass))
    this.paneTargets.forEach(pane => pane.classList.remove(this.activeClass))

    this.currentTab.classList.add(this.activeClass)

    if (!this.currentPane) return

    this.currentPane.classList.add(this.activeClass)
  }
}
