import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["defaultItem", "itemList"]
  static classes = ["active"]

  connect() {
    this.setActiveItem()
  }

  setActiveItem(element=null) {
    if(element == null) {
      element = this.defaultItemTarget
    }

    Array.from(this.itemListTarget.children).forEach((item) => {
      item.classList.remove(this.activeClass) }
    )

    element.classList.add(this.activeClass)
  }

  activate(event) {
    this.setActiveItem(event.currentTarget)
  }
}
