import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["hidden", "textLabel"]
  static classes = ["hidden"]
  static values = {
    open: { type: Boolean, default: false },
    moreText: { type: String, default: "Show more" },
    lessText: { type: String, default: "Show less" }
  }

  connect() {
    this.openValue === true ? this.show() : this.hide()
  }

  toggle() {
    this.openValue === false ? this.show() : this.hide()
  }

  show() {
    this.openValue = true
    this.hiddenTargets.forEach((target) => target.classList.remove(this.hiddenClass))
    this.textLabelTarget.textContent = this.lessTextValue
  }
  
  hide() {
    this.openValue = false
    this.hiddenTargets.forEach((target) => target.classList.add(this.hiddenClass))
    this.textLabelTarget.textContent = this.moreTextValue
  }
}
