import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["slug"]

  toggleSlugOverwrite() {
    this.slugTarget.disabled = !this.slugTarget.disabled
  }

  uploadAvatar(event) {
    debugger
    const file = event.target.files[0];
    const reader = new FileReader();
    const wrapper = event.target.closest(".avatarContainer");
    reader.onloadend = () => {
      document.getElementById('avatar_profile').src = reader.result;
    };

    if (file) {
      reader.readAsDataURL(file);
      this.saveAvatar(file, wrapper);
    }
  }

  async saveAvatar(file, wrapper) {
    const formData = new FormData();
    formData.append('user_profile[avatar]', file);

    try {
      const response = await fetch('/my/profile/avatar', {
        method: 'PATCH',
        body: formData,
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        }
      });

      if (response.ok) {
        this.showSuccessMessage(wrapper);
      } else {
        this.showErrorMessage(wrapper);
      }
    } catch (error) {
      console.error('Error saving avatar:', error);
      this.showErrorMessage(wrapper);
    }
  }

  showSuccessMessage(wrapper) {
    const snackbar = document.createElement('div');
    snackbar.classList.add('snackbar', 'show');
    snackbar.innerHTML = '<i class="fa fa-check-circle text-success"></i> Profile image updated successfully';
    wrapper.appendChild(snackbar);
    setTimeout(() => {
      snackbar.remove();
    }, 3000);
  }

  showErrorMessage(wrapper) {
    const snackbar = document.createElement('div');
    snackbar.classList.add('snackbar', 'show');
    snackbar.innerHTML = '<i class="fa fa-times-circle text-danger"></i> There is an error while uploading! Please try again later.';
    wrapper.appendChild(snackbar);
    setTimeout(() => {
      snackbar.remove();
    }, 3000);
  }
}
