import { Controller } from "@hotwired/stimulus"
import Swiper from "swiper/bundle"

export default class extends Controller {
  static targets = ["stage", "slide", "foreignSlide"]
  static values = { swiperOptions: Object }
  static outlets = ["carousel"]

  connect() {
    this.swiper = new Swiper(this.stageTarget.parentNode, this.swiperOptions())

    if (this.hasCarouselOutlet) {
      this.handleForeignSlides()

      this.swiper.on("resize", () => {
        this.handleForeignSlides()
      })
    }
  }

  swiperOptions() {
    let defaultOptions = {
      slidesPerView: 1,
      spaceBetween: 32,
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      }
    }
    
    return Object.assign(defaultOptions, this.swiperOptionsValue)
  }

  handleForeignSlides() {
    if(window.innerWidth < 768) {
      if (this.hasForeignSlideTarget) return

      this.carouselOutlets.forEach((outlet) => {
        outlet.slideTargets.forEach((foreignSlide) => {
          let slide = foreignSlide.cloneNode(true)
          slide.setAttribute("data-carousel-target", "foreignSlide")
          this.swiper.prependSlide(slide)
        })
      })
    }
    else {
      this.foreignSlideTargets.forEach((slide) => {
        let slideIndex = this.swiper.slides.indexOf(slide)
        this.swiper.removeSlide(slideIndex)
      })
    }
  }
}
