import { Controller } from "@hotwired/stimulus"

export default class extends Controller {  
  static targets = ["option", "default"]

  connect() {
    this.optionTargets.forEach(element => {
      element.dataset.action = [element.dataset.action, "click->currency#switch"].join(" ").trim()
    })

    const target = this.hasDefaultTarget ? this.defaultTarget : this.optionTarget
    this.setCurrent(target)
  }

  setCurrent(element) {
    this.current = {
      name: element.dataset.currencyName,
      symbol: element.dataset.currencySymbol,
    }

    document.dispatchEvent(
      new CustomEvent("currency-change", { detail: this.current })
    )
  }

  switch(event) {
    this.setCurrent(event.currentTarget)
  }
}
