import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal"
export default class extends Controller {
  static classes = ["open"]

  connect() {
    // When the modal is shown, we want a fixed body
    document.body.style.position = 'fixed'
    document.body.style.top = `-${window.scrollY}px`

    this.element.classList.add(this.openClass)
  }

  close() {
    const scrollY = document.body.style.top
    document.body.style.position = ''
    document.body.style.top = ''
    window.scrollTo(0, parseInt(scrollY || '0') * -1)

    this.element.classList.remove(this.openClass)
  }
}
