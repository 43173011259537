import { Controller } from "@hotwired/stimulus"
import { createChart } from 'lightweight-charts';
import dayjs from 'dayjs';

export default class extends Controller {
  static values = {
    priceEndpoint: String,
    currency: { type: String, default: 'ada' }
  }

  static classes = ['priceUp', 'priceDown']

  async setCurrency(currency) {
    if (this.currencyValue != currency.name.toLowerCase()) {
      this.currencyValue = currency.name.toLowerCase();
      this.useExistingPriceData = true

      this.renderChart()
    }
  }

  async connect() {
    this.initChart()
    this.renderChart()

    document.addEventListener("currency-change", event => { this.setCurrency(event.detail) })
  }

  async initChart() {
    this.chart = createChart(this.element, {
      width: 136, // Set the desired width
      height: 44, // Set the desired height
      layout: {
        background: {
          type: 'solid',
          color: 'transparent',
        },
      },
      grid: {
        horzLines: {
          color: 'transparent', // Hide horizontal grid lines
        },
        vertLines: {
          color: 'transparent', // Hide vertical grid lines
        },
      },
      rightPriceScale: {
        visible: false, // Hide the right price scale
      },
      timeScale: {
        visible: false, // Hide the time scale
      },
      crosshair: {
        mode: "none", // Disable the crosshair tooltip
        horzLine: {
          visible: false, // Hide horizontal crosshair line
        },
        vertLine: {
          visible: false, // Hide vertical crosshair line
        },
        // Disable the crosshair point and price label dot
        point: {
          visible: false,
        },
        price: {
          visible: false,
        },
      },
      crosshairMarkerVisible: {
          visible: false,
      },
      pointMarkersVisible: {
          visible: false,
      },
      handleScroll: false, // Disable scroll events
      handleScale: false, // Disable zoom events
    });

    this.lineSeries = this.chart.addLineSeries({
        lineWidth: 1, // Set the line width
        priceLineVisible: false, // Display the price line
    });
  }

  async renderChart() {
    if(this.useExistingPriceData != true) {
      this.priceData = await this.getPriceData();
    }
    
    const formattedPriceData = this.formatPriceData(this.priceData['7d_'+this.currencyValue])

    this.lineSeries.setData(formattedPriceData);

    this.lineSeries.applyOptions({
      color: this.checkPriceDown(this.priceData['7d_price_change_'+this.currencyValue]) ? '#FF2C2C' : '#00D061'
    });

    this.chart.timeScale().fitContent();
  }

  async getPriceData() {
    return fetch(this.priceEndpointValue, {
      method: 'GET',
      headers: { 'Accept': 'application/json' }
    }).then (response => response.json());
  }

  formatPriceData(dataObject) {
    const formattedData = [];

    for (const timestamp in dataObject) {
      if (dataObject.hasOwnProperty(timestamp)) {
        const value = dataObject[timestamp];
        formattedData.push({ time: dayjs(timestamp).unix(), value: value });
      }
    }

    // Sort the formatted data by time in ascending order
    formattedData.sort((a, b) => a.time - b.time);
    return formattedData;
  }

  checkPriceDown(price) {
    return price < 0 ? true : false;
  }
}
