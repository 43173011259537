
import { Controller } from "@hotwired/stimulus"
import { Toast } from "bootstrap"

export default class extends Controller {
  static targets = [ "toast" ]

  toastTargetConnected(element) {
    new Toast(element, {delay: 5000}).show();
  }
}
