import { Controller } from "@hotwired/stimulus"
import slugify from "@sindresorhus/slugify"

export default class extends Controller {
  static targets = ["tocWrapper", "body"]
  static classes = ["tocLinkActive"]
  static values = {
    allowToc: Boolean, default: false,
    tocWrapperClasses: String,
    tocLinkClasses: String,
  }

  connect() {
    if (!this.hasTocWrapperTarget || !this.hasBodyTarget){
      console.error("missing targets: tocWrapper or body")
      return
    }
    else {
      this.collectHeadlines()
  
      if(this.shouldHaveToc) {
        this.buildToc()
      }
    }
  }

  shouldHaveToc() {
    if(this.allowTocValue && this.headlines.length > 1) {
      return true
    } else {
      return false
    }
  }

  collectHeadlines() {
    this.headlines = []

    Array.from(this.bodyTarget.children).forEach((child) => {
      if(child.tagName.match(/H[2-3]/)) {
        this.headlines.push(child)
      }
    })
  }

  buildToc() {
    let tocLinksHTML = ""

    this.headlines.forEach((headline) => {
      let level = parseInt(headline.tagName.slice(1)) - 1
      let anchor = slugify(headline.textContent)
      let text = headline.textContent
      headline.id = anchor

      tocLinksHTML += `<a href="#${anchor}" class="${this.tocLinkClassesValue} toc-level-${level}">${text}</a>`
    })

    this.tocWrapperTarget.innerHTML = `<div class="${this.tocWrapperClassesValue}">${tocLinksHTML}</div>`
    this.highlightTocItem()
  }

  highlightTocItem() {
    let currentHeadline = null;

    for (let i = 0; i < this.headlines.length; i++) {
      let headline = this.headlines[i];
      let nextHeadline = this.headlines[i + 1]
      let rect = headline.getBoundingClientRect();

      if (rect.top <= 50) {
        if (nextHeadline && nextHeadline.getBoundingClientRect().top > 50) {
          currentHeadline = headline
          break
        }
        else if (!nextHeadline) {
          currentHeadline = headline;
          break;
        }
      }
    }

    if (currentHeadline) {
      let currentAnchor = currentHeadline.id;
      let currentTocItem = this.tocWrapperTarget.querySelector(`a[href="#${currentAnchor}"]`);

      if (currentTocItem) {
        this.removeAllHighlights()
        
        currentTocItem.classList.add(this.tocLinkActiveClass);
      }
    } else {
      this.removeAllHighlights()
    }
  }

  removeAllHighlights() {
    this.tocWrapperTarget.querySelectorAll("a").forEach((tocItem) => {
      tocItem.classList.remove(this.tocLinkActiveClass);
    });
  }
}
